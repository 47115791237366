import { Page } from './page.model';
import { User } from './user.model';

export class Tour {
  _id: string;
  name: string;
  description: string;
  locations: [
    {
      _id: string;
      location: string;
      noOfTickets: number;
      pricePerTicket: number;
      startDateTime: string;
    }
  ];
  page: Page;
  createdBy: User;
  tourimageurl: string;
  imagePath: string;
  isActive: boolean;

  /**
   * Constructor
   *
   * @param tour
   */
  constructor(tour?) {
    tour = tour || {};
    this._id = tour._id || '';
    this.name = tour.name || '';
    this.description = tour.description || '';
    this.locations = tour.locations || [
      {
        _id: '',
        location: '',
        noOfTickets: 0,
        pricePerTicket: 0,
        startDateTime: '',
      },
    ];
    this.page = tour.page || '';
    this.createdBy = tour.createdBy || '';
    this.tourimageurl = tour.tourimageurl || '';
    this.imagePath = tour.imagePath || '';
    this.isActive = tour.isActive || true;
  }
}
