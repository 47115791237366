/* eslint-disable @typescript-eslint/naming-convention */
export enum Event {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  INITIATE_CHAT = 'initChat',
  CHAT_INITIATED = 'chatInitiated',
  FETCH_CHATS = 'fetchChats',
  FETCH_CHAT_MESSAGES = 'fetchMessages',
  ADD_MESSAGE = 'addMessage',
  MESSAGE_ADDED = 'messageAdded',
}
