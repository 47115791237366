import { Page } from './page.model';
import { User } from './user.model';

export class Product {
  _id: string;
  name: string;
  description: string;
  page: Page;
  createdBy: User;
  images: any[];
  isActive: boolean;
  variantLabel: string;
  variants: [
    {
      _id: string;
      product: any;
      label: string;
      stock: number;
      price: number;
    }
  ];
  deliveryFee: number;
  selectedPrice?: any;
  acceptedAppFeeTnC: boolean;

  /**
   * Constructor
   *
   * @param product
   */
  constructor(product?) {
    product = product || {};
    this._id = product._id || '';
    this.name = product.name || '';
    this.description = product.description || '';

    this.page = product.page || '';
    this.createdBy = product.createdBy || '';
    this.images = product.images || '';
    this.isActive = product.isActive || false;
    this.variantLabel = product.variantLabel || '';
    this.variants = product.variants || [
      {
        _id: '',
        product: '',
        label: '',
        stock: '',
        price: '',
      },
    ];
    this.deliveryFee = product.deliveryFee || 0;
    this.acceptedAppFeeTnC = product.acceptedAppFeeTnC || true;
  }
}
