import { Page } from './page.model';
import { User } from './user.model';

export class Feed {
  _id: string;
  type: string;
  content: string;
  mediaUrl: string;
  mediaurl: string;
  images: File;
  createdBy: User;
  likes: any[];
  likeCount: number;
  shares: any[];
  shareCount: number;
  comment: any[];
  commentCount: number;
  isDisabled: boolean;
  page: Page;
  isLiked: boolean;
  pollOptions: any[];
  pollEndsOn: Date;
  totalVote: number;
  isPollFinished: boolean;
  isVoted: boolean;
  createdAt: Date;
  feedObj: any;
  sharedFeed: any;

  /**
   * Constructor
   *
   * @param tour
   */
  constructor(feed?) {
    feed = feed || {};
    this._id = feed._id || '';
    this.type = feed.type || '';
    this.content = feed.content || '';
    this.mediaUrl = feed.mediaUrl || '';
    this.createdBy = feed.createdBy || '';
    this.likes = feed.likes || [];
    this.likeCount = feed.likeCount || 0;
    this.comment = feed.comment || [];
    this.commentCount = feed.commentCount || 0;
    this.shares = feed.shares || [];
    this.shareCount = feed.shareCount || 0;
    this.isDisabled = feed.isDisabled || false;
    this.page = feed.page || '';
    this.images = feed.images || '';
    this.isLiked = feed.isLiked || false;
  }
}

export interface Comment {
  _id: string;
  page: any;
  comment: string;
  feed: string;
  parentId: string;
  createdBy: string;
}
