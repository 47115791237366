import { Page } from './page.model';
import { User } from './user.model';

export class Video {
  _id: string;
  name: string;
  link: string;
  page: Page;
  createdBy: User;
  videoId: string;
  isActive: boolean;

  /**
   * Constructor
   *
   * @param tour
   */
  constructor(video?) {
    video = video || {};
    this._id = video._id || '';
    this.name = video.name || '';
    this.link = video.link || '';
    this.page = video.page || '';
    this.createdBy = video.createdBy || '';
    this.videoId = video.videoId || '';
    this.isActive = video.isActive || true;
  }
}
