import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CustomSnackbarService, UserService } from 'app/core/services';
import { PageService } from 'app/core/services/page.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfficeResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    private _pageService: PageService,
    private _customSnackbarService: CustomSnackbarService,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const userPage = this._userService.userObj.page;
    return this._pageService.getPage(userPage).pipe(
      catchError((err: any) => {
        this._customSnackbarService.open(err, 'error', 'Ok');
        throw new Error(err);
      })
    );
  }
}
